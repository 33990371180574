.Wrapper {
  font-family: Helvetica Neue;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 16px;
}

.EmptySvg {
  width: 182.393px;
  height: 196.606px;
  flex-shrink: 0;
}

.Title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 24px;
}

.Description {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 20px;
}
