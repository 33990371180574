.modalRoot {
  padding: 24px !important;
}

.modalPaperClassName {
  min-width: 618px;
  animation: animateRoot 0.5s ease forwards;
}

@keyframes animateRoot {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.question {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  opacity: 0.85;
  margin-bottom: 14px;
}

.question span {
  color: #ff0c00;
}

.infoRoot {
  display: flex;
  gap: 24px;
  padding: 12px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #27252266;
  background: #f6f6f6;
  margin: 0 -24px;
  margin-top: -24px;
  margin-bottom: 19px;
}

.info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.info::before {
  content: "";
  height: 7px;
  width: 7px;
  display: inline-block;
  border-radius: 50%;
}

.correct::before {
  background: #00a570;
}

.inCorrect::before {
  background: #aa2822;
}

.emptyState {
  padding: 0 !important;
  text-align: center;
}

.loader {
  text-align: center;
}

@media screen and (max-width: 780px) {
  .modalRoot {
    padding: 16px !important;
  }

  .infoRoot {
    padding: 12px 16px;
    margin: 0 -16px;
    margin-top: -16px;
    margin-bottom: 19px;
  }
}
