.modalRoot {
  width: 428px;
}

.root {
  padding: 24px !important;
}

.loading {
  display: grid;
  place-items: center;
  min-height: 200px;
}

.note {
  background-color: transparent;
  color: #27252280;
  padding: 0px;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 16px !important;
  }
}
