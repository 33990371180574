.container {
  border-radius: 5px;
  height: 256px;
  border: 1px solid rgba(241, 240, 239, 1);
  box-shadow: 0px 6px 8px 0px rgba(164, 163, 174, 0.2);
}

.name {
  font-size: 16px;
  font-weight: 500;
}

.history {
  font-size: 12px;
  color: rgba(73, 58, 177, 1);
  text-decoration: underline;
}

.history_icon {
  font-size: 12px !important;
  color: rgba(73, 58, 177, 1) !important;
}

.limit_label {
  font-size: 14px;
  color: rgba(39, 37, 34, 1);
}

.limit_text {
  font-size: 12px;
  color: rgba(39, 37, 34, 0.4);
}

.credit_progress {
  background: none;
  height: 8px;
  width: 100%;
}

.credit_progress > * {
  background-color: rgba(73, 58, 177, 1) !important;
}

.priceText {
  font-size: 18px;
  font-weight: 700;
  color: rgba(39, 37, 34, 1);
}

.quantityText {
  font-size: 14px;
  font-weight: 400;
  color: rgba(39, 37, 34, 0.5);
}

@media screen and (max-width: 780px) {
  .container {
    width: 100%;
    height: 204px;
  }
}
