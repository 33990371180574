.body {
  padding: 24px;
  animation: animateRoot 0.5s ease forwards;
}

.statsRoot {
  animation: animateRoot 0.5s ease forwards;
}

@keyframes animateRoot {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.learners {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #272522;
  opacity: 0.6;
  letter-spacing: 0.04em;
  margin-top: 24px;
  margin-bottom: 16px;
}

.footer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.secondaryBtn {
  white-space: nowrap;
  text-align: center;
  color: #493ab1 !important;
  border: 1.5px solid #493ab1 !important;
}

@media screen and (max-width: 768px) {
  .body {
    padding: 0;
  }

  .bulkActionLabel {
    justify-content: space-between;
    margin-bottom: 0px;
    width: 100%;
  }
}
