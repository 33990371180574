.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 24px;
  border-bottom: 1.5px solid #e7e6e4;
  min-height: 69px;
  animation: animateRoot 0.5s ease forwards;
}

@keyframes animateRoot {
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.back {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #272522;
}

.back button {
  padding: 0;
}

.back h1 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin: 0;
}

.cta {
  display: flex;
  gap: 16px;
}
