.info_icon {
  font-size: 16px !important;
}

.title {
  font-weight: 700;
  font-size: 18px;
  color: rgba(39, 37, 34, 1);
}

.transactions > * {
  color: rgba(0, 82, 204, 1) !important;
}

.tooltip {
  border: 1px solid rgba(73, 58, 177, 1);
}

.tooltipArrow {
  color: rgba(73, 58, 177, 1) !important;
}

.cartContainer {
  font-size: 18px !important;
  color: rgba(39, 37, 34, 1) !important;
}

.separator {
  background-color: rgba(224, 222, 220, 1);
  height: 2px;
}

.cartCount {
  background-color: rgba(191, 38, 0, 1);
  color: white;
  height: 12px;
  width: 12px;
  font-size: 10px;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}

.totalPrice {
  font-size: 16px;
  font-weight: 700px;
}

.itemCount {
  font-size: 12px;
  font-weight: 400;
}

.cartIcon {
  font-size: 14px !important;
}

.cart {
  font-size: 14px;
}

.header {
  position: sticky;
  top: 50px;
  background-color: white;
}
