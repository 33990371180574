.title {
  color: #493ab1;
}

.body {
  height: -webkit-fill-available;
  padding: 24px;
  animation: animateRoot 0.5s ease forwards;
}

@keyframes animateRoot {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .body {
    padding: 16px;
  }
}
