.container {
  background-color: rgba(252, 251, 254, 1);
}

.successStatus {
  color: rgba(0, 183, 122, 1);
}

.failureStatus {
  color: rgba(191, 38, 0, 1);
}

.pendingStatus {
  color: #ffaa00;
}

.invoiceContainer > * {
  color: rgba(73, 58, 177, 1) !important;
  font-size: 14px !important;
}

.loupeIcon {
  background: linear-gradient(rgba(73, 58, 177, 0.2), rgba(64, 155, 204, 0.2));
  padding: 5px;
  border-radius: 50%;
}

.loupeIcon > :first-child {
  fill: rgba(73, 58, 177, 1) !important;
  font-size: 22px !important;
}

.autoFixIcon {
  background: linear-gradient(rgba(255, 203, 100, 0.5), rgba(255, 171, 3, 0.5));
  padding: 5px;
  border-radius: 50%;
}

.autoFixIcon > :first-child {
  fill: rgba(51, 34, 0, 1) !important;
  height: 22px !important;
  width: 22px !important;
}

.title {
  color: rgba(73, 58, 177, 1);
}

.dataModal {
  width: 300px;
}
