.LeftSection {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 12px;
}

.coverIcon {
  width: 79.68px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coverImage {
  width: 79.68px;
  height: 52px;
  background: #d9d9d9;
  border-radius: 5px;
  object-fit: cover;
}

.smallIcon {
  width: 55px;
  height: 36px;
}

.smallImg {
  width: 55px;
  height: 36px;
}

.font_14 {
  font-size: 14px;
  line-height: 17px;
  overflow-wrap: anywhere;
}

.font_12 {
  font-size: 12px;
  line-height: 14px;
  overflow-wrap: anywhere;
}

.salePgOptionWrapper {
  border-bottom: 1.5px solid #cacaca;
  padding: 12px 16px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.status {
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 5px;
  white-space: nowrap;
}

.fine_pine {
  background: #00b779;
}
.coral_red {
  background: #ee2626cc;
}
.butterscotch_yellow {
  background: #e3963e;
}
.danger_red {
  background: #bf2600;
}
