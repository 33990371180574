.modalPaperClassName {
  max-width: 428px !important;
}

.root {
  padding: 24px !important;
}

.info {
  color: #272522;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.28px;
  margin-bottom: 12px !important;
}

.secondaryBtn {
  color: #272522 !important;
  border: 1.5px solid #272522 !important;
}
.secondaryBtn:hover {
  background-color: lightgray !important;
}

.rankContainer {
  position: relative;
}

.newRank {
  display: flex;
  align-items: center;
  color: #272522;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  gap: 10px;
  position: absolute;
  top: 53%;
  transform: translateY(-50%);
  left: 0.6px;
}

.currentRank {
  display: flex;
  align-items: center;
  color: rgba(39, 37, 34, 0.5);
  font-size: 14px;
  letter-spacing: 0.28px;
  position: absolute;
  bottom: -5px;
  gap: 11px;
  left: 1.5px;
}

.currentRankTop {
  bottom: auto;
  top: -5px;
}

.newRankLow {
  top: 46%;
}

.downIcon {
  margin-top: -7px;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 16px !important;
  }

  .footerClassName {
    gap: 16px;
  }
}
