.first_index_style {
  margin-left: 10px !important;
}

.dropdown {
  outline: none;
  background: #e8e8e8;
  border: 2px solid #e8e8e8;
  border-radius: 4px;
  margin: 0 10px 0 0;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.dropdown_datepicker_container {
  gap: 10px;
}

.total_sales_icon_style {
  width: 36px !important;
  height: 36px !important;
}

.empty_item {
  width: fit-content;
  margin: auto;
}

.empty_cta {
  margin: 80px 0 0 0;
}

.stats_container {
  border-color: #493ab1;
  padding: 0 20px;
  margin-top: 10px;
}

.empty_title {
  font-size: 20px;
  font-weight: 600;
  padding: 80px 0 0 0;
  text-align: center;
}

.mobile_calendar {
  height: 500px;
}

.preview_image {
  height: 100%;
  width: 100%;
  min-width: 780px;
  object-fit: cover;
}

.flex_row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.chart_action_container {
  position: absolute;
  right: 0;
  margin: 10px 20px 0 40px;
  width: fit-content;
}

.leads_dashboard_card {
  background-color: #6c5dd3;
  color: white;
  margin: 10px;
  height: 70px;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.lead_label {
  font-size: 20px;
  font-weight: 700;
}

.lead_value {
  font-size: 18px;
  font-weight: 600;
  /* margin-top: 10px; */
}

.chart_tooltip {
  color: black;
  font-weight: 600;
  padding: 10px;
  background-color: white;
  border: 1px solid #e0e0e0;
}

.analyticsWrapper {
  width: 1180px;
}

.close {
  color: #6c5dd3;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.wa_svg {
  fill: #6c5dd3 !important;
  font-size: 27px !important;
  margin: -1px 3px 0 3px;
  cursor: pointer;
}

.top_margin {
  margin-top: 30px;
}

.banner_cta {
  max-width: 28% !important;
}

.banner_image {
  max-height: 130px;
  float: right;
}

.checkout {
  width: fit-content;
}

.axis_tick {
  font-size: 12px;
}

.quote_loader {
  position: absolute;
  top: 0%;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.banner_loader_wrapper {
  height: 10vh;
}

.circleStyle {
  width: "100%";
  height: "100%";
  stroke: "#6c5dd3";
}

.trafficCompare,
.analyticComparedata,
.trafficContainer {
  display: flex;
}

.trafficCompare {
  justify-content: space-between;
  background-color: #f6f9fc;
  padding: 25px;
  border-radius: 8px;
  margin-top: 20px;
}

.live_traffic {
  width: 100%;
  padding-left: 20px;
}
.headingIcon {
  display: flex;
}

.analyticCompare {
  width: 340px;
  border-right: 0.1px solid rgba(0, 0, 0, 0.16);
}
.liveProfile {
  padding: 10px;
  margin: auto;
}
.trafficContainer {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.26);
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}
.trafficData {
  margin-left: 20px;
}
.trafficPayment {
  justify-content: right;
}
.trafficleftContent {
  display: flex;
  align-items: center;
}
.analyticComparedata {
  margin-top: 10px;
  display: flex;
}
.previous {
  margin-right: 50px;
}
.heading {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}
.subheading {
  font-weight: 600;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.54);
  text-transform: capitalize;
  font-size: 18px;
}
.headingProfile {
  font-weight: 600;
  font-size: 22px;
}
.chartmargin {
  margin-left: -40px;
  margin-left: 2px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 4px;
  background-color: white;
}
.chartmargin > div {
  margin-left: -28px;
}
.headinglive {
  display: flex;
  justify-content: space-between;
}
.subcount {
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 600;
}
.subcount span {
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  font-weight: 400;
}
.positiveResult {
  color: green;
  display: flex;
  justify-content: center;
  font-weight: 700;
  margin-left: -10px;
}
.negativeResult {
  color: red;
  display: flex;
  justify-content: center;
  font-weight: 700;
}
.zeroResult {
  display: flex;
}
.positiveResult > div,
.negativeResult > div,
.zeroResult > div {
  text-align: center;
  margin-right: 5px;
  padding: 2px 10px;
  border-radius: 80px;
}
.zeroResult > div {
  background-color: #e8e8e8;
}
.negativeResult > div {
  background-color: #ffb2b2;
}
.positiveResult > div {
  background-color: #bcf5bc;
}
.shimm {
  margin-top: 30px;
}
.headingProfile2 {
  font-size: 14px;
}
.headers {
  display: block;
}
.refresh {
  color: #6c5dd3;
  border: none;
  outline: none;
  border-radius: 5px;
  height: 30px;
  margin: auto 0;
}
.bookingHover {
  display: none;
}
.amount {
  display: block;
  position: relative;
}
.customtool {
  position: relative;
}
.tooltip {
  right: unset;
  top: -40%;
  font-size: 14px;
}
.amount:hover .bookingHover {
  display: block;
  background-color: #6c5dd3;
  color: white;
  padding: 5px 15px;
  border-radius: 10px;
  position: absolute;
  bottom: -10px;
  width: 300px;
  right: 35px;
}
.trafficOverflow {
  overflow: auto;
  height: 242px;
  padding: 5px 10px;
}
.trafficOverflow::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.trafficOverflow::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.trafficOverflow::-webkit-scrollbar-thumb {
  background: #6c5dd3;
  border-radius: 10px;
}

.subdomain {
  text-align: center;
  display: flex;
  justify-content: center;
}
.subdomain > div {
  margin-right: 10px;
}
.headingProfile2Mobile {
  display: none;
}
.empty_cta_live {
  margin: 20px 0 0 0;
}

.whatsNextCardTitle {
  font-size: 20px;
  font-weight: 500;
}

.banner_container {
  padding: 10px;
  border-radius: 4px;
}

.white_bold_medium_text {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.white_small_text {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.flexBox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 1rem;
  margin-bottom: 3px;
}

.link_btn {
  background-color: #ffffff;
  padding: 5px 10px;
  color: #6c5dd3;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  max-height: 35px;
}

.link_btn:hover {
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  .bar_chart_wrapper {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1370px) {
  .flex_row {
    display: block;
    flex-direction: column;
  }

  .chart_action_container {
    position: relative;
    margin: 5px 10px;
  }
}

@media screen and (max-width: 780px) {
  .flexBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0px;
  }

  .stats_container {
    padding: 0 10px;
  }

  .dropdown {
    font-size: 12px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .chart_action_container {
    position: relative;
    margin: 5px 10px;
  }

  .chart {
    padding: 0 0 0 5px;
  }

  .checkout {
    width: 90%;
  }

  .preview_image {
    height: auto;
    width: 100%;
    min-width: 100%;
  }

  .quote_loader {
    width: 90%;
  }

  .trafficCompare {
    display: block;
    padding: 10px;
  }

  .live_traffic {
    width: 100%;
    margin: 20px 0;
    padding: 5px;
  }

  .analyticCompare {
    width: 100%;
    margin-left: 0;
    border-right: none;
  }

  .trafficContainer {
    padding: 10px 5px;
  }

  .chartmargin > div {
    margin-left: auto;
  }

  .liveProfile {
    width: 90vw;
    padding: 5px;
  }

  .trafficData {
    margin-left: 10px;
  }

  .trafficData div {
    font-size: 14px;
  }

  .tooltip {
    width: 170px;
    top: auto;
  }
  .customtool:hover .tooltip {
    visibility: visible !important;
  }
  .trafficPayment {
    justify-content: right;
    white-space: nowrap;
    margin-left: 5px;
  }
  .headingProfile2Mobile {
    display: block;
    font-size: 14px;
  }
  .headingProfile2 {
    display: none;
  }

  .leads_dashboard_container {
    margin-top: 15px;
  }

  .widthOverflowHandle {
    width: 100vw !important;
  }
}
