.questionRoot {
  background-color: white;
}

.questionTitle {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  padding: 16px;
  border-bottom: 1px solid #e7e6e4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.questionBody {
  padding: 16px;
}

.questionFooter {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7e6e4;
  background: white;
  border-radius: 0 0 12px 12px;
  gap: 4px;
}

.tooltipContainer {
  z-index: 1102;
  position: relative;
}

.toolTipBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1101;
  transition: opacity 0.3s ease;
}

.interactiveToolTipPopper {
  z-index: 99999 !important;
}

.questionMarks {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  letter-spacing: 0.28px;
}

.modalFooterRoot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-top: 2px solid #e7e6e4;
  min-height: 68px;
}

.scoreInputRoot {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(
    169deg,
    #edebff 11.13%,
    #f2f0ff 54.2%,
    #f2f0ff 66.75%,
    #e6e2ff 100%
  );
}

.scoreInputTitle {
  color: rgba(73, 58, 177, 0.85);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  padding: 5px 12px;
  white-space: nowrap;
}

.scoreInput {
  border-radius: 0px 8px 8px 0px !important;
  border: 1.5px solid #e6e3ff;
  background: #fff;
  width: 72px;
}

.notchedOutline {
  border: none !important;
}

.checkboxClassName svg {
  margin-right: 0 !important;
}

@media screen and (max-width: 780px) {
  .questionFooter {
    padding: 10px 12px;
  }

  .toolTipBackdrop {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
