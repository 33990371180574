.modalRoot {
  padding: 24px !important;
  background: #eeecf9;
}

.modalPaperClassName {
  min-width: 562px;
  animation: animateRoot 0.5s ease forwards;
}

@keyframes animateRoot {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.emptyState {
  padding: 0 !important;
  text-align: center;
}

.loader {
  text-align: center;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 780px) {
  .modalRoot {
    padding: 16px !important;
  }
}
